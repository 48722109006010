import { getToken } from './auth'
import * as domainDoctor from './domain-doctor'
import * as domainMr from './domain-mr'
import * as domainYun from './domain-yundata'

const onQueryStr = data => {
  // &拼接参数
  let queryStr = ''
  if (typeof data === 'object') {
    for (const item in data) {
      queryStr += `&${item}=${data[item]}`
    }
  }
  return queryStr
}

const domainRoute = {
  // --------------------------- domainMr 代表端 -----------------------------
  casePracticePreview(previewId, sceneType, token) {
    return `${domainDoctor.casePracticePreview}/${previewId}?sceneType=${sceneType}&isPreView=true&token=${token}`
  },

  // --------------------------- domainMr 代表端 -----------------------------
  coursePreview(previewId, type, previewType, noShowBtn) {
    return (
      `${domainMr.coursePreview
      }/${previewId}?type=${type}&previewType=${previewType}&noShowBtn=${noShowBtn}&token=${getToken()}`
    )
  },

  //  ------------------------------- 云脑 - 路由 domainFactoryData ----------------------
  rank(data) {
    return `${domainYun.rank  }?a=b${  onQueryStr(data)}`
  }
}
export default domainRoute
