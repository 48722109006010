<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    v-bind="$attrs"
    class="factory-modal"
    width="416px"
    v-on="$listeners"
  >
    <div v-if="previewUrl" class="content-box">
      <svg-icon name="iphone" class="phone-icon" />
      <div class="content">
        <iframe
          class="content-iframe"
          :src="previewUrl"
          frameborder="0"
          @loaderror="handleError"
        />
      </div>
    </div>
    <div class="flex level-center vertical-center footer">
      <el-button
        v-if="showSend"
        round
        type="primary"
        @click="onPreview"
      >
        发送到手机预览
      </el-button>
      <el-button round @click="onClose">
        关闭预览
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'CommonPreviewModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    previewUrl: {
      type: String,
      default: ''
    },
    showSend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      noShowBtn: 'show'
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onPreview() {
      this.$emit('onPreview')
    },
    onClose() {
      this.visible = false
      this.$emit('onClose')
    },
    handleError() {
      console.log('🚀 ~ handleError ~ error:')
    }
  }
}
</script>
<style lang="scss" scoped>
.factory-modal {
  ::v-deep .el-dialog {
    background: transparent;
    box-shadow: unset;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
    .el-dialog__footer {
      border-top: 1px solid #e9eaeb;
      padding: 30px 70px;
      display: flex;
      justify-content: center;
    }
  }
}
.content-box {
  width: 416px;
  height: 852px;
  padding: 14px 18px;
  position: relative;
  background-size: cover;
  .phone-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .content {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 59px;
    padding: 20px 0;
    .content-iframe {
      width: 100%;
      height: 100%;
      border-radius: 35px;
    }
  }
}
.btn {
  padding: 10px 12px;
  background: white;
  font-size: 14px;
  border-radius: 20px;
  width: 140px;
  margin: 0 7px;
  color: $color-primary;
  &.btn-default {
    background: $color-primary;
    color: white;
  }
}
.footer {
  margin-top: 15px;
}
</style>
